#loading-outer {
    div {
    display:inline-block;
    -webkit-text-stroke:1px #fff;
  }

  #loading-inner {
    position:relative;
    height:30px;
    width:30px;
    display:inline-block;
    perspective:1000px;
    transform-style:preserve-3d;
    animation-name: cuberota;
    animation-duration:90s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;

    div {
      position:absolute;
      display:block;
      top:0;
      left:0;
      height:28px;
      width:28px;
      border:1px solid black;
      backface-visibility:visible;
    }

    #back {
      transform: rotateY(180deg) translateZ(-30px);
    }
    #top {
      transform: rotateX(90deg) translate3d(0,15px,15px);
    }
    #bottom {
      transform: rotateX(-90deg) translate3d(0,-15px,15px);
    }
    #left {
      transform: rotateY(-90deg) translate3d(15px, 0, 15px);
    }
    #right {
      transform: rotateY(90deg) translate3d(-15px, 0, 15px);
    }
  }
  @keyframes cuberota {
    0% {transform-style: preserve-3d; transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateZ(-15px);}
    100% {transform-style: preserve-3d; transform: rotateX(720deg) rotateY(1440deg) rotateZ(360deg) translateZ(-15px);}
  }
}