@import 'assets/styles/variables';
@import 'assets/styles/fonts';
@import 'assets/styles/fontello';

html {
  background-color: #FEFEFE;
}

html,
body,
div[id="root"] {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
  font-family: $defaultFont, Arial, Helvetica, sans-serif;
  color: $darkColor;
  background-color: #000000;

}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
*,
::after,
::before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

li {
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.slide-enter-done {
  transform: translateX(0);
}