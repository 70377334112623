@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins.scss";

.main {
  @include flexbox();
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #000000;

}