@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ArialBold';
  src: url('../fonts/ArialBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}
